import React from 'react';

import { ImageRatio } from 'settings/consts';

import type { IGroup } from 'store/groups/types';

import { Box } from 'wui/Box';
import { Skeleton } from 'wui/Skeleton';
import { ListItemIcon } from 'wui/ListItemIcon';
import { AspectRatio } from 'wui/AspectRatio';

import { GroupImage } from 'common/components/GroupImage';

interface IGroupListItemImageProps {
  group: IGroup;
}

export function GroupListItemImage(props: IGroupListItemImageProps) {
  const { group } = props;

  return (
    <ListItemIcon>
      <Box width={40}>
        <GroupImage
          width={40}
          resize="cover"
          aspectRatio={ImageRatio.square}
          image={group.coverImage?.image}
        />
      </Box>
    </ListItemIcon>
  );
}

GroupListItemImage.displayName = 'GroupListItemImage';

export function GroupListItemImageSkeleton() {
  return (
    <ListItemIcon>
      <AspectRatio width={40} aspectRatio={ImageRatio.square}>
        <Skeleton variant="rect" width="100%" height="100%" />
      </AspectRatio>
    </ListItemIcon>
  );
}

GroupListItemImageSkeleton.displayName = 'GroupListItemImageSkeleton';
