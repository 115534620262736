import React from 'react';
import cls from 'classnames';

import { UISref, useRouter } from '@wix/tpa-router/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';
import { IconButton } from 'wui/IconButton';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import settingsParams from 'Groups/settingsParams';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { useSettings } from '@wix/tpa-settings/react';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { Button } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Typography } from 'wui/Typography';
import { Wire } from 'wui/Wire';
import type { TPAComponentProps } from 'wui/types';

import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';

import { useBiParams } from 'common/hooks/useBiParams';

import { GroupListItemImage } from './GroupListItemImage';

import classes from './GroupListItem.scss';

interface IGroupListItemProps extends TPAComponentProps {
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const router = useRouter();
  const settings = useSettings();

  const biParams = useBiParams();

  if (isMobile) {
    return (
      <ListItem disablePadding {...rest}>
        <ListItemButton
          onClick={() => handleGroupClick('click_on_group')}
          className={cls(classes.root, classes.mobile)}
        >
          <Wire
            cssVarName="showImage--flex"
            legacyFallback={settings.get(settingsParams.showImage)}
          >
            <GroupListItemImage group={group} />
          </Wire>

          <ListItemText
            title={
              <Box verticalAlign="middle">
                <Typography
                  noWrap
                  variant="p2-14"
                  data-hook="group-name"
                  // className={cls(classes.title, classes.mobile)}
                >
                  {group.name}
                </Typography>
              </Box>
            }
            subtitleProps={{ noWrap: true }}
            subtitle={
              <GroupInfo
                wired
                misc
                data-hook="group-info"
                groupId={group.id as string}
              />
            }
          />
          <ListItemAction>
            <IconButton tabIndex={-1} icon={<ChevronRightIcon />} />
          </ListItemAction>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem {...rest} className={cls(classes.root, props.className)}>
      <Wire
        cssVarName="showImage--inline"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsLivesiteClick({
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'click_on_group',
          })}
        >
          <a tabIndex={-1}>
            <GroupListItemImage group={group} />
          </a>
        </UISref>
      </Wire>

      <ListItemText
        title={
          <Box verticalAlign="middle">
            <Typography variant="p2-16" noWrap>
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsLivesiteClick({
                  screen_name: biParams.groupsScreenWithTab(),
                  button_name: 'click_on_group',
                })}
              >
                <TextButton as="a" variant="secondary" data-hook="group-name">
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>
          </Box>
        }
        subtitle={
          <GroupInfo
            data-hook="group-info"
            groupId={group.id as string}
            variant="p2-14"
            noPendingMembers
          />
        }
      />

      <ListItemAction>
        <Button
          size="tiny"
          outlined
          onClick={() => handleGroupClick('view_group')}
          className={classes.tinyButton}
        >
          {t('groups-web.btn.view-group')}
        </Button>
      </ListItemAction>
    </ListItem>
  );

  function handleGroupClick(biOrigin: string) {
    return router.go('group', { slug: group.slug });
  }
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
