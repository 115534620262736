import React from 'react';
import cls from 'classnames';
import {
  useEnvironment,
  useTranslation,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { Alignment, LayoutType } from 'settings/consts';

import stylesParams from 'Groups/stylesParams';
import settingsParams from 'Groups/settingsParams';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { TextButton } from 'wui/TextButton';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Typography } from 'wui/Typography';
import { type TPAComponentProps } from 'wui/types';
import { IconButton } from 'wui/IconButton';

import { CentralFeed } from '../../CentralFeed/loadable';
import { PendingGroups } from '../../PendingGroups';
import { Search } from '../../Search';

import { Sidebar } from './Sidebar';
import { GroupsDialog } from './GroupsDialog';

import { GROUP_LIST_LINK_DATA_HOOK } from './dataHooks';

import classes from './SidebarLayout.scss';

export function SidebarLayout(props: TPAComponentProps) {
  const { className, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const styles = useStyles();

  const { htmlTag: htmlTitleTag } = styles.get(stylesParams.feedTitleFont);
  const { htmlTag: htmlSubtitleTag } = styles.get(
    stylesParams.feedSubtitleFont,
  );

  const [isGroupPickerOpened, setIsGroupPickerOpened] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const canSearch = experiments.enabled('specs.groups.GroupSearch');

  return (
    <div
      data-type={LayoutType.sideBar}
      className={cls(classes.root, {
        [classes.flip]:
          settings.get(settingsParams.sideBarAlignment) === Alignment.left,
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      <Show if={isMobile}>
        <Box padding={isMobile ? 'SP0 SP5' : 'SP0'} direction="vertical">
          <Box gap="SP2" align="space-between" className={classes.header}>
            <Wire
              cssVarName="showFeedTitle--flex"
              legacyFallback={settings.get(settingsParams.showFeedTitle)}
            >
              <Typography
                as={htmlTitleTag as React.ElementType}
                variant="h2-20"
                id="feed-title"
                className={classes.title}
                align={
                  settings.get(
                    settingsParams.headerAlignment,
                  ) as React.CSSProperties['textAlign']
                }
              >
                {settings.get(settingsParams.feedTitle)}
              </Typography>
            </Wire>

            <Box gap="SP5">
              <TextButton
                data-hook={GROUP_LIST_LINK_DATA_HOOK}
                onClick={handleClick}
              >
                {t('groups-web.group-list.label')}
              </TextButton>
              <Show if={canSearch}>
                <IconButton
                  data-hook="search-button"
                  icon={<SearchIcon />}
                  onClick={() => setIsSearchOpen(true)}
                />
              </Show>
            </Box>

            <Search
              isOpen={isSearchOpen}
              onClose={() => setIsSearchOpen(false)}
            />
            <GroupsDialog
              isOpen={
                isGroupPickerOpened &&
                !experiments.enabled('specs.groups.GroupSearchh')
              }
              onClose={() => setIsGroupPickerOpened(false)}
            />
          </Box>
          <Wire
            cssVarName="showFeedSubtitle--flex"
            legacyFallback={settings.get(settingsParams.showFeedSubtitle)}
          >
            <Typography
              as={htmlSubtitleTag as React.ElementType}
              variant="p2-16"
              id="feed-subtitle"
              className={classes.subtitle}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedSubtitle)}
            </Typography>
          </Wire>
        </Box>
      </Show>

      <Show if={!isMobile}>
        <Box direction="vertical" gap={8}>
          <Wire
            cssVarName="showFeedTitle--flex"
            legacyFallback={settings.get(settingsParams.showFeedTitle)}
          >
            <Box
              gap="SP2"
              align="space-between"
              className={classes.header}
              padding={isMobile ? 'SP0 SP5' : 'SP0'}
            >
              <Typography
                as={htmlTitleTag as React.ElementType}
                variant="h2-20"
                id="feed-title"
                className={classes.title}
                align={
                  settings.get(
                    settingsParams.headerAlignment,
                  ) as React.CSSProperties['textAlign']
                }
              >
                {settings.get(settingsParams.feedTitle)}
              </Typography>
            </Box>
          </Wire>
          <Wire
            cssVarName="showFeedSubtitle--flex"
            legacyFallback={settings.get(settingsParams.showFeedSubtitle)}
          >
            <Typography
              as={htmlSubtitleTag as React.ElementType}
              variant="p2-16"
              id="feed-subtitle"
              className={classes.subtitle}
              align={
                settings.get(
                  settingsParams.headerAlignment,
                ) as React.CSSProperties['textAlign']
              }
            >
              {settings.get(settingsParams.feedSubtitle)}
            </Typography>
          </Wire>
        </Box>

        <div className={classes.sidebar}>
          <Sidebar />
        </div>
      </Show>

      <Box
        direction="vertical"
        className={classes.main}
        gap={styles.get(stylesParams.sideBarVerticalSpacing)}
      >
        <PendingGroups />
        <CentralFeed />
      </Box>
    </div>
  );

  function handleClick() {
    setIsGroupPickerOpened(true);
  }
}

SidebarLayout.displayName = 'SidebarLayout';
